<script setup>
import ViewBills from "@/components/billing/view_bills/ViewBills.vue";
import ActionButton from "@/components/utils/ActionButton.vue";
import { billStatuses } from "@/components/billing/view_bills/view_bill_business_logic";
import SectionTitle from "@/components/billing/SectionTitle.vue";
import { useRouter } from "@/utils/vue_helpers";
import { routes } from "@/router/routes";
import { countyIsKirinyaga } from "@/utils/vue_helpers";
import { computed, ref, watchEffect } from "vue";
import { actions } from "@/utils/constants";
import { arrayNotEmpty } from "@/utils/functions";
import BackButton from "../utils/BackButton.vue";
import AttachProperty from "./AttachProperty.vue";
import BuildingPlanDetails from "./BuildingPlanDetails.vue";
import AttachArchitectEngineer from "./AttachArchitectEngineer.vue";
import AttachRequiredDocuments from "./AttachRequiredDocuments.vue";
import SubmitBuildingPlan from "./SubmitBuildingPlan.vue";





const { router, route } = useRouter();

const tabs = {
  LINKED_PROPERTIES: 0,
  BUILDING_PLAN_DETAILS: 1,
  ATTACH_CLIENT_ARCHITECT: 2,
  SUPPORTING_DOCUMENTS: 3,
  SUBMIT_BUILDING_PLAN: 4,
};

let currentTab = ref(tabs.LINKED_PROPERTIES);

function goToLinkedPropertiesTab(){
  currentTab.value = tabs.LINKED_PROPERTIES;

}

function goToBuildingPlanDetailsTab(){
  currentTab.value = tabs.BUILDING_PLAN_DETAILS;

}

function goToAttachClientTab(){
  currentTab.value = tabs.ATTACH_CLIENT_ARCHITECT;

}

function goToSupportTingDocsTab(){
  currentTab.value = tabs.SUPPORTING_DOCUMENTS;

}

function goToSubmitBuildingPlanTab(){
  currentTab.value = tabs.SUBMIT_BUILDING_PLAN;

}

function myFunction(){
  console.log("My Function 12345")

}


// watchEffect(() => {
//   // const routeTabQuery = route.value?.query?.tab ?? null;
//   // const routeHasTabQuery = !!routeTabQuery;
//   // if (routeHasTabQuery) {
//   //   const shouldGoToUnpaid = routeTabQuery === "step_1";
//   //   const shouldGoToPaid = routeTabQuery === "step_2";
//   //   if (shouldGoToUnpaid) {
//   //     currentTab.value = tabs.UNPAID_BILLS;
//   //   } else if (shouldGoToPaid) {
//   //     currentTab.value = tabs.PAID_BILLS;
//   //   }
//   // }
// });


// function getBillIdsAsCommaSeparatedString(bills) {
//   let billIdsString = "";
//   bills.forEach((bill, index) => {
//     billIdsString += bill.id;
//     const notLastItem = index !== bills.length - 1;
//     if (notLastItem) {
//       billIdsString += ",";
//     }
//   });
//   return billIdsString;
// }

// async function onPayBillClicked(bill) {
//   const paymentRoute = routes.payment.withQuery({
//     billNumbersToPayAsString: bill.bill_number,
//     directPayment: true,
//     billsToPay: [bill],
//   });
//   router.value.push(paymentRoute);
// }

const tabText = computed(() => {
  if (countyIsKirinyaga.value) {
    return {
      step_1: "Linked Properties",
      step_2: "Building Plan Details",
      step_3: "Client/Architect Details",
      step_4: "Supporting Documents",
      step_5: "Submit Building Plan Application",

    };
  }
  return {
    step_1: "Linked Properties",
    step_2: "Building Plan Details",
    step_3: "Client/Architect Details",
    step_4: "Supporting Documents",
    step_5: "Submit Building Plan Application",
  };
});

const billsSelectedForPayment = ref([]);

// function billIsSelectedForPayment(bill) {
//   const selectedBill = billsSelectedForPayment.value.find(
//     (billToPay) => billToPay.bill_number === bill.bill_number
//   );
//   const selectedBillNotEmpty = selectedBill !== undefined;
//   return selectedBillNotEmpty;
// }

// const atLeastOneBillMultiSelected = computed(() => {
//   return arrayNotEmpty(billsSelectedForPayment.value);
// });

// function onPayForMultipleBillsClicked() {
//   const billNumbersAsCommaSeparatedString = billsSelectedForPayment.value
//     .map((bill) => bill.bill_number)
//     .join(",");
//   const paymentRoute = routes.payment.withQuery({
//     billNumbersToPayAsString: billNumbersAsCommaSeparatedString,
//     directPayment: true,
//     billsToPay: billsSelectedForPayment.value,
//   });
//   router.value.push(paymentRoute);
// }

// function comparatorFn(a, b) {
//   return a.bill_number === b.bill_number;
// }
</script>

<template>
  <div>

    <v-row>
      <v-col cols="12" md="1">
          <BackButton @click="$emit('goBackClicked')">
            Back To Buildings
          </BackButton>
      </v-col>

      <!-- <v-col>
        <SectionTitle title="Bills And Receipts" />
      </v-col> -->
      
    </v-row>

    <v-col>
        <SectionTitle title="Create Building Application" />
    </v-col>

    <v-tabs
      v-model="currentTab"
      :class="{
        'pt-4 pt-md-0 justify-center justify-md-start': true,
        'd-flex': $mobileBreakpoint,
      }"
    >
      <v-tabs-slider color="secondary" />

      <v-tab @click.stop.prevent="myFunction" v-for="text in tabText" :key="text" class="text-none">
        {{ text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentTab" touchless class="pt-4 pt-md-0">
      <v-tab-item>
          <AttachProperty @goToPreviousStep="$emit('goBackClicked')" @goToNextStep="goToBuildingPlanDetailsTab()"/>
      </v-tab-item>

      <v-tab-item>
          <BuildingPlanDetails @goToPreviousStep="goToLinkedPropertiesTab" @goToNextStep="goToAttachClientTab()"/>
      </v-tab-item>

      <v-tab-item>
          <AttachArchitectEngineer @goToPreviousStep="goToBuildingPlanDetailsTab" @goToNextStep="goToSupportTingDocsTab()"/>
      </v-tab-item>

      <v-tab-item>
          <AttachRequiredDocuments @goToPreviousStep="goToAttachClientTab" @goToNextStep="goToSubmitBuildingPlanTab()"/>
      </v-tab-item>

      <v-tab-item>
          <SubmitBuildingPlan @goToPreviousStep="goToSupportTingDocsTab"/>
      </v-tab-item>

      

    </v-tabs-items>

  </div>
</template>
