<script setup>
import Table from "@/components/utils/Table.vue";
import {
  getFormattedMoney,
  getFormattedBillDate,
  getPaymode,
  arrayNotEmpty,
} from "@/utils/functions.js";
import { actions } from "@/utils/constants";
import ActionButton from "@/components/utils/ActionButton.vue";
import { onMounted } from "vue"
import { useDownloadFile } from "@/utils/vue_helpers";

const { downloadFile } = useDownloadFile();

const props = defineProps({
  buildings: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

function viewBuilding(item){
  console.log("ViewBuilding")
  console.log(item)

}

onMounted(() => {
  console.log("onMounted Table")
  console.log(props.buildings)


})

function onPrintBillClicked(bill_object) {
  downloadFile({ billId: bill_object.id, billNumber: bill_object.bill_number });

}

function onPrintReceipt(receipt_object) {
  downloadFile({ receiptNumber: receipt_object.receipt_number });

}

</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12">
        <div :style="{ position: 'relative' }">
          <Table :headers="[
            {
              text: 'Application Type',
              value: 'is_renewal',
              fn: (house) => house.is_renewal == '1' ? 'Renewal' : 'Initial Application'

            },

            {
              text: 'Application Number',
              value: 'approval_number',
              fn: (house) => house.building.approval_number

            },

            {
              text: 'Customer Name',
              value: 'is_renewal',
              fn: (house) => house.building.building_owner.length > 0 ? house.building.building_owner[0].clients.customer_name : ' '

            },

            {
              text: 'Architect Name',
              value: 'building_architect',
              fn: (house) => house.building.building_architect.length > 0 ? house.building.building_architect[0].architects.first_name + ' ' + house.building.building_architect[0].architects.last_name : ' ',

            },

            {
              text: 'Type Of Development',
              value: 'type_of_development',
              fn: (house) => house.building.building_development_type.type_of_development,
              
            },

            {
              text: 'Application Date',
              value: 'is_renewal',
              fn: (house) => getFormattedBillDate(house.valid_from)

            },

            {
              text: 'Expiration Date',
              value: 'valid_to',
              fn: (house) => getFormattedBillDate(house.valid_to)

            },

            {
              text: 'Building Status',
              value: 'is_renewal',
              fn: (house) => house.building.status.status

            },

            {
              text: 'Application Status',
              value: 'status',
              fn: (house) => house.workflow_status.status_name
            },

            {
              text: 'Actions',
              value: 'view',
              action: true,
              fn: (house) => house.workflow_status.status_name
            },


            // { text: 'Pay', value: 'is_renewal', action: true },
          ]" :items="buildings" :loading="loading" emptyText="No Buildings Found" headClasses="primary white--text">

            <template #item.view="{ item }">
              <div>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="button mr-1 primary--text" @click="$emit('viewBuilding', item)" v-bind="attrs"
                      v-on="on">

                      <v-icon small> mdi-eye </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("viewMsg") }}</span>
                </v-tooltip>

                <v-tooltip v-if="item.building_application_bill.bills.bills_invoices[0].invoice_receipt" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon
                      @click="onPrintReceipt(item.building_application_bill.bills.bills_invoices[0].invoice_receipt.receipt)"
                      class="button mr-1 success--text" v-bind="attrs" v-on="on">
                      <v-icon small>
                        mdi-printer
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Download Receipt</span>
                </v-tooltip>

                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="onPrintBillClicked(item.building_application_bill.bills)" class="button mr-1 error--text"
                      v-bind="attrs" v-on="on">
                      <v-icon small>
                        mdi-printer
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>No Receipt Available, Please Pay for this Bill</span>
                </v-tooltip>

                <v-tooltip top
                  v-if="item.building.status.status != 'DEMOLISHED' && item.workflow_status.status_name != 'In Progress'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon class="button mr-1 success--text" @click="renewBuildingApplication(item)" v-bind="attrs"
                      v-on="on">
                      <v-icon medium> mdi-restart </v-icon>
                    </v-btn>
                  </template>
                  <span>Renew Building Application</span>
                </v-tooltip>


              </div>


            </template>
            <template #item.getBill="{ item: house }">
              <ActionButton :action="actions.pay" inTable @click="$emit('payForHouse', house)" />
            </template>

            <template #item.getReceipt="{ item: house }">
              <ActionButton :action="actions.pay" inTable @click="$emit('payForHouse', house)" />
            </template>
          </Table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
