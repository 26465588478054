<script setup>

import Vue from "vue";
import Table from "@/components/utils/Table.vue";

import apiCall from "@/utils/api.js";

import { loadingBuildingPlan, createBuildingAppData, attachedDocumentsList, generateBuildingBillItemsExportFun, snackBarInfoFunction, generate_bill_data, my_documents } from "./buildings_business_logic.js";
import TableEdited from "../utils/TableEdited.vue";
import { useDownloadFile, useRouter } from "@/utils/vue_helpers";

import PreviousButton from "@/components/utils/PreviousButton.vue";
import NextButton from "@/components/utils/NextButton.vue";
import { ref, reactive, onMounted } from "vue";

const { snackbar_info } = snackBarInfoFunction()

const { create_building_aplication_data, building_additional_data } = createBuildingAppData()
const { attached_documents, attached_documents_with_share_certificate, attachedDocumentsModel } = attachedDocumentsList()
const { generateBuildingBillItems } = generateBuildingBillItemsExportFun()
const { downloadFile } = useDownloadFile();

const emit = defineEmits(['goToPreviousStep'])

var numeral = require("numeral");
Vue.use(require("vue-moment"));
Vue.filter("formatNumber", function (value) {
    return numeral(value).format("0,0");
});

const createBillForBpaDialog = ref(false)
const building_bill_items = ref([])
const current_view = ref('submit_application_view')
const bill_items_array = ref([])
const bill_object = ref({})

let create_building_aplication_data_copy = null

onMounted(() => {

});

function verifyAndGoToPreviousStep() {
    emit('goToPreviousStep')

}

function returnNumberOfFloors(num_of_floors) {
    if (num_of_floors) {
        return num_of_floors

    }
    return 'N/A'

}

function returnTotalPlinthAreas(plinth_area) {
    if (plinth_area != null) {
        return plinth_area + ' Sq Meters'

    }

    let total_area = 0

    create_building_aplication_data.plinth_areas_array.forEach((item) => {
        total_area = total_area + Number(item)

    })

    return total_area + ' Sq Meters'

}

function prepareToSubmitBuildingPlan() {
    if (create_building_aplication_data.clients_array_data.length == 0) {
        snackBarErrorMessage("Please attach a client", 4000)
        return
        
    }
    
    generate_bill_data.client_id = create_building_aplication_data.clients_array_data[0].id
    generate_bill_data.unit_of_measure_id = create_building_aplication_data.unit_of_measure_id
    generate_bill_data.plinth_area = create_building_aplication_data.plinth_area
    generate_bill_data.plinth_areas_array = create_building_aplication_data.plinth_areas_array
    generate_bill_data.number_of_floors = create_building_aplication_data.number_of_floors
    generate_bill_data.development_type_id = create_building_aplication_data.type_of_development_id
    generate_bill_data.perimeter_wall_length = create_building_aplication_data.perimeter_wall_length
    generate_bill_data.type_of_application = create_building_aplication_data.type_of_application

    loadingBuildingPlan.value = true

    const response = generateBuildingBillItems()

    response.then(function (result) {
        if (result.status == "success") {
            building_bill_items.value = result.bill_items
            createBillForBpaDialog.value = true

        }

        if (result.status == "error") {
            snackBarErrorMessage(result.message)

        }

        loadingBuildingPlan.value = false

    });

}

function getMyBillsSubTotal(my_bills) {
    console.log("Inside getMyBillsSubTotal");
    console.log(my_bills);

    let total = 0;
    for (let i = 0; i < my_bills.length; i++) {
        total = total + parseFloat(my_bills[i].sub_total);

    }
    return total;

}

function snackBarErrorMessage(message, time) {
    snackbar_info.snackbar_message = message
    snackbar_info.snackbar_timeout = time ? time : 5000
    snackbar_info.color = "error"
    snackbar_info.snackbar = true

}

function snackBarSuccessMessage(message, time) {
    snackbar_info.snackbar_message = message
    snackbar_info.snackbar_timeout = time ? time : 5000
    snackbar_info.color = "success"
    snackbar_info.snackbar = true

}

function changeView(my_view_name) {
    current_view.value = my_view_name

}

function onDownloadBillClicked() {
    downloadFile({ billId: bill_object.value.id, billNumber: bill_object.value.bill_number });

}

function scrollToTop() {
    window.scrollTo(0, 0);
}

async function verifyBuildingApplication() {
    create_building_aplication_data_copy = create_building_aplication_data
    const properties_array_data = []
    const clients_array_data = []
    const architects_array_data = []
    const structural_eng_array_data = []

    create_building_aplication_data_copy.created_by = create_building_aplication_data_copy.clients_array_data[0].id
    create_building_aplication_data_copy.updated_by = create_building_aplication_data_copy.clients_array_data[0].id


    if (create_building_aplication_data_copy.properties_array_data.length) {
        create_building_aplication_data_copy.properties_array_data.forEach((item, index) => {
            let my_object = { id: null }
            my_object.id = item.id
            properties_array_data.push(my_object)

        });

    }

    if (create_building_aplication_data_copy.clients_array_data.length) {
        create_building_aplication_data_copy.clients_array_data.forEach((item, index) => {
            let my_object = { id: null }
            my_object.id = item.id
            clients_array_data.push(my_object)

        });

    }

    if (create_building_aplication_data_copy.architects_array_data.length) {
        create_building_aplication_data_copy.architects_array_data.forEach((item, index) => {
            let my_object = { id: null }
            my_object.id = item.id
            architects_array_data.push(my_object)

        });

    }

    if (create_building_aplication_data_copy.structural_eng_array_data.length) {
        create_building_aplication_data_copy.structural_eng_array_data.forEach((item, index) => {
            let my_object = { id: null }
            my_object.id = item.id
            structural_eng_array_data.push(my_object)

        });

    }

    create_building_aplication_data_copy.properties_array_data = properties_array_data
    create_building_aplication_data_copy.clients_array_data = clients_array_data
    create_building_aplication_data_copy.architects_array_data = architects_array_data
    create_building_aplication_data_copy.structural_eng_array_data = structural_eng_array_data

    loadingBuildingPlan.value = true    

    await apiCall({
        url: "/api/client/verifyBuildingApplicationData",
        data: create_building_aplication_data_copy,
        method: "POST",
    })
        .then((resp) => {
            //resp.status
            console.log("resp verifyBuildingApplicationData");
            console.log(resp);
            if (resp.status == "success") {
                submitDocumentsForBuildingApplication()

            }
            else if(resp.status == "error"){
                snackBarErrorMessage(resp.message, 5000)
                console.log("Response Error A")
                console.log(resp)
                loadingBuildingPlan.value = false

            }
            else {
                snackBarErrorMessage("Error Occured, please contact admin.", 5000)
                console.log("Response Error")
                console.log(resp)
                loadingBuildingPlan.value = false

            }


        })
        .catch((error) => {
            console.log("error verifyBuildingApplicationData");
            console.log(error);

            let error_string = '';

            if(error?.response?.data?.message){
                if (error?.response?.data?.message == "The given data was invalid.") {
                    Object.entries(error.response.data.errors).forEach(([key, value]) => {
                        error_string = error_string + value + "\n"

                    });
                    
                }

            }

            if (error_string != '') {
                snackBarErrorMessage(error_string, 8000)
                
            }
            else{
                snackBarErrorMessage(error, 8000)
            }

            loadingBuildingPlan.value = false

        });

}


async function submitDocumentsForBuildingApplication() {
    // Now lets create a building application
    //Lets start by submitting all submitting all documentsmy_documents

    create_building_aplication_data.document_ids_list = []
    loadingBuildingPlan.value = true

    for (let i = 0; i < attachedDocumentsModel.length; i++) {
        let status = ''

        console.log("attachedDocumentsModel.length")
        console.log(i)
        console.log(attachedDocumentsModel.length)

        if (typeof attachedDocumentsModel[i].type != "undefined") {
            const formData = new FormData();
            formData.append("files", attachedDocumentsModel[i]);
            formData.append("application_id", '');
            formData.append("application_type", "Building");
            formData.append("document_name", my_documents.value[i]);

            const headers = {
                "Content-Type": "multipart/form-data",
            };

            //this.submit_document_data_model.application_id = 20;

            await apiCall(
                {
                    url: "/api/client/storeDocument",
                    data: formData,
                    method: "POST",
                },
                {
                    headers,
                }
            )
                .then((resp) => {

                    console.log("alert succ store doc");
                    console.log(resp);

                    if (resp.status == "success") {
                        create_building_aplication_data.document_ids_list.push(resp.document_id)

                    }

                    if (resp.status == "error") {
                        snackBarErrorMessage("Error occured when uploading documents")
                        status = resp.status
                        loadingBuildingPlan.value = false

                    }

                })
                .catch((error) => {
                    console.log("error occured store doc");
                    console.log(error);
                    loadingBuildingPlan.value = false
                    status = "error"

                });

        }

        if (status == "error") {
            loadingBuildingPlan.value = false
            break

        }

        if (i == (attachedDocumentsModel.length - 1)) {
            console.log("Document_Uploaded_End")
            submitBuildingApplication()
            snackBarSuccessMessage("Documents uploaded successfully")

        }

    }

}

async function submitBuildingApplication() {

    await apiCall({
        url: "/api/client/createBuildingApplicationModule",
        data: create_building_aplication_data_copy,
        method: "POST",
    })
        .then((resp) => {
            //resp.status
            console.log("resp submitBuildingApplication");
            console.log(resp);
            if (resp.status == "success") {
                snackBarSuccessMessage("Building Plan Created successfully", 5000)
                createBillForBpaDialog.value = false
                bill_items_array.value = resp.bill_items
                bill_object.value = resp.bill
                changeView('view_bill_view')
                scrollToTop()

            }
            else {
                snackBarErrorMessage("Error Occured, please contact admin.", 5000)
                console.log("Response Error")
                console.log(resp)

            }

            loadingBuildingPlan.value = false


        })
        .catch((error) => {
            console.log("error submitBuildingApplication");
            console.log(error);
            snackBarErrorMessage(error, 5000)
            loadingBuildingPlan.value = false

        });

}



</script>

<template>
    <v-container v-if="current_view == 'submit_application_view'" fluid class="pa-0">
        <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Building Plan Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <div>
                    <b>Parcel Number :</b>{{ create_building_aplication_data.parcel_no }}
                </div>

                <div>
                    <b>Sub County :</b>{{ building_additional_data.sub_county_name }}
                </div>

                <div>
                    <b>Ward :</b>{{ building_additional_data.ward_name }}
                </div>

                <div>
                    <b>Town :</b>{{ building_additional_data.town_name }}
                </div>

                <div>
                    <b>Latitude :</b>{{ create_building_aplication_data.latitude }}
                </div>

                <div>
                    <b>Longitude :</b>{{ create_building_aplication_data.longitude }}
                </div>

            </v-col>

            <v-col cols="12" md="5">
                <div>
                    <b>Cost of Building :</b>{{ create_building_aplication_data.cost }}
                </div>

                <div>
                    <b>Unit of Measure :</b>{{ building_additional_data.unit_of_measure }}
                </div>

                <div>
                    <b>Plinth Area :</b>{{ returnTotalPlinthAreas(create_building_aplication_data.plinth_area) }}
                </div>

                <div>
                    <b>Type of Development :</b>{{ building_additional_data.type_of_development_name }}
                </div>

                <div>
                    <b>Number of Floors :</b>{{ returnNumberOfFloors(create_building_aplication_data.number_of_floors)
                    }}
                </div>

                <div>
                    <b>Physical Location/Landmark :</b>{{ create_building_aplication_data.physical_address }}
                </div>

            </v-col>

        </v-row>


        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Architect Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row v-if="create_building_aplication_data.architects_array_data.length">
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[
                                {
                                    text: 'Architect Name',
                                    value: 'customer_name',
                                    fn: (architectsData) => architectsData.first_name ? architectsData.first_name + ' ' + architectsData.last_name : architectsData.organisation_name

                                },

                                {
                                    text: 'Architect No',
                                    value: 'architect_number',

                                },

                                {
                                    text: 'Telephone No',
                                    value: 'phone_number',

                                },

                                {
                                    text: 'Email',
                                    value: 'email_address',

                                },

                            ]" :items="create_building_aplication_data.architects_array_data"
                                emptyText="No Architects Found" headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>


        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Client Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row v-if="create_building_aplication_data.clients_array_data.length">
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[
                                {
                                    text: 'Customer Name',
                                    value: 'customer_name',

                                },

                                {
                                    text: 'Phone No',
                                    value: 'phone_number1',

                                },

                                {
                                    text: 'ID No',
                                    value: 'id_number',

                                },

                                {
                                    text: 'Client Type',
                                    value: 'client_type',


                                },

                            ]" :items="create_building_aplication_data.clients_array_data" emptyText="No Clients Found"
                                headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Structural Engineer Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row v-if="create_building_aplication_data.structural_eng_array_data.length">
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[
                                {
                                    text: 'Structural Engineer Name',
                                    value: 'customer_name',
                                    fn: (structuralEngData) => structuralEngData.first_name ? structuralEngData.first_name + ' ' + structuralEngData.last_name : structuralEngData.organisation_name

                                },

                                {
                                    text: 'Engineer No',
                                    value: 'structural_engineer_number',

                                },

                                {
                                    text: 'Telephone No',
                                    value: 'phone_number',

                                },

                                {
                                    text: 'Email',
                                    value: 'email_address',

                                },

                            ]" :items="create_building_aplication_data.structural_eng_array_data"
                                emptyText="No Structural Engineers Found" headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Properties Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row v-if="create_building_aplication_data.properties_array_data.length">
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[

                                {
                                    text: 'Sub County',
                                    value: 'sub_county',
                                    fn: (item) => item.sub_county ? item.sub_county.sub_county_name : ''

                                },

                                {
                                    text: 'Ward',
                                    value: 'ward',
                                    fn: (item) => item.ward ? item.ward.ward_name : ''

                                },

                                {
                                    text: 'Town',
                                    value: 'town',
                                    fn: (item) => item.town ? item.town.town_name : ''

                                },


                                {
                                    text: 'PRN No',
                                    value: 'prn'

                                },

                                {
                                    text: 'LR Number',
                                    value: 'lr_no'

                                },


                                {
                                    text: 'Block / Plot Number',
                                    value: 'block_plot_no',
                                    fn: (property) => (property.block_no || property.plot_no) ? property.block_no + '/' + property.plot_no : ''

                                },

                                {
                                    text: 'Physical Location',
                                    value: 'physical_location'

                                },

                            ]" :items="create_building_aplication_data.properties_array_data"
                                emptyText="No Properties Found" headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Files</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <TableEdited :headers="[
                                {
                                    text: 'No.',
                                    value: 'item',

                                },

                                {
                                    text: 'File Name',
                                    value: 'item',

                                },

                                {
                                    text: 'Original Name',
                                    value: 'item',

                                },

                                {
                                    text: 'File Type',
                                    value: 'item',

                                },

                            ]" :items="attachedDocumentsModel" emptyText="No Documents Found"
                                dataBeingHandled="Documents" headClasses="primary white--text">

                            </TableEdited>


                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>


        <v-row class="mt-2" justify="center" justify-md="end">
            <v-col>
                <PreviousButton @click="verifyAndGoToPreviousStep" />

            </v-col>

            <v-col>
                <v-btn rounded :loading="loadingBuildingPlan" class="text-none mb-4" color="success" size="x-large"
                    block @click="prepareToSubmitBuildingPlan()">
                    Submit Application

                    <v-icon right dark>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>

            </v-col>
        </v-row>


        <!-- Create Finall Bill Dialog Starts Here -->
        <v-row justify="center">
            <v-dialog v-model="createBillForBpaDialog" max-width="600">
                <v-card>
                    <!-- <v-card-title class="primary white--text" align="center">
                        Bill Items Summary

                    </v-card-title> -->

                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click="createBillForBpaDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Bill Items Summary</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        <b class="primary--text">No.</b>
                                    </th>
                                    <th class="text-left">
                                        <b class="primary--text">Description</b>
                                    </th>
                                    <th class="text-left">
                                        <b class="primary--text">Amount</b>
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="building_bill_items.length == 1">
                                <tr>
                                    <td colspan="2">
                                        <span style="color: brown">No Bills Generated</span>
                                    </td>
                                </tr>
                            </tbody>

                            <tbody v-else>
                                <tr v-for="(item, index) in building_bill_items" :key="index">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.fee_name }}</td>
                                    <td>Kshs.{{ item.sub_total | formatNumber }}</td>
                                </tr>
                            </tbody>

                            <tbody>
                                <tr>
                                    <td><b></b></td>
                                    <td><b>Sub Total</b></td>
                                    <td>
                                        <b>Kshs.{{
                                            getMyBillsSubTotal(building_bill_items) | formatNumber
                                            }}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn class="secondary text-none mt-3" text @click="createBillForBpaDialog = false">
                            Close
                        </v-btn>

                        <v-btn depressed class="accent text-none mt-3" @click="verifyBuildingApplication()"
                            :loading="loadingBuildingPlan">
                            Confirm
                            <v-icon right> mdi-check </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- Create Final Bill Dialog Ends Here -->

    </v-container>

    <v-container v-else-if="current_view == 'view_bill_view'" fluid class="pa-0">
        <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Building Plan Details</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <div>
                    <b>Parcel Number :</b>{{ create_building_aplication_data.parcel_no }}
                </div>

                <div>
                    <b>Sub County :</b>{{ building_additional_data.sub_county_name }}
                </div>

                <div>
                    <b>Ward :</b>{{ building_additional_data.ward_name }}
                </div>

                <div>
                    <b>Town :</b>{{ building_additional_data.town_name }}
                </div>

                <div>
                    <b>Latitude :</b>{{ create_building_aplication_data.latitude }}
                </div>

                <div>
                    <b>Longitude :</b>{{ create_building_aplication_data.longitude }}
                </div>

            </v-col>

            <v-col cols="12" md="5">
                <div>
                    <b>Cost of Building :</b>{{ create_building_aplication_data.cost }}
                </div>

                <div>
                    <b>Unit of Measure :</b>{{ building_additional_data.unit_of_measure }}
                </div>

                <div>
                    <b>Plinth Area :</b>{{ returnTotalPlinthAreas(create_building_aplication_data.plinth_area) }}
                </div>

                <div>
                    <b>Type of Development :</b>{{ building_additional_data.type_of_development_name }}
                </div>

                <div>
                    <b>Number of Floors :</b>{{ returnNumberOfFloors(create_building_aplication_data.number_of_floors)
                    }}
                </div>

                <div>
                    <b>Physical Location/Landmark :</b>{{ create_building_aplication_data.physical_address }}
                </div>

            </v-col>

        </v-row>

        <v-row>
            <v-col cols="12" md="5">
                <v-tabs show-arrows="">
                    <v-tabs-slider color="secondary"></v-tabs-slider>
                    <v-tab class="text-none">Bill Items</v-tab>
                </v-tabs>
            </v-col>

        </v-row>

        <v-row v-if="bill_items_array.length">
            <v-col cols="12">

                <v-container fluid class="pa-0">
                    <v-row>
                        <v-col cols="12">

                            <Table :headers="[
                                {
                                    text: 'No.',
                                    value: 'No.',
                                    fn: (bill_items_array_item) => bill_items_array_item ? bill_items_array.indexOf(bill_items_array_item) + 1 : ''

                                },

                                {
                                    text: 'Fee Name',
                                    value: 'fee_name',

                                },

                                {
                                    text: 'Quantity',
                                    value: 'quantity',

                                },

                                {
                                    text: 'Sub Total',
                                    value: 'sub_total',
                                    fn: (bill_items_array_item) => bill_items_array_item ? 'Kshs. ' + bill_items_array_item.sub_total : ''


                                },

                            ]" :items="bill_items_array" emptyText="No Bill Items Found"
                                headClasses="primary white--text">

                            </Table>

                        </v-col>
                    </v-row>

                </v-container>

            </v-col>
        </v-row>

        <v-row justify="center" justify-md="start">
            <v-col cols="12" md="auto">
                <v-btn class="text-none" color="secondary" depressed @click="onDownloadBillClicked()">
                    Print Bill <v-icon right>mdi-printer</v-icon>
                </v-btn>

            </v-col>

            <v-col cols="12" md="auto">
                <v-btn class="text-none" color="success" depressed @click="printBuildingApplication()">
                    Print Building Application <v-icon right>mdi-printer</v-icon>
                </v-btn>

            </v-col>
        </v-row>

        <v-row justify="center" justify-md="start">
            <v-col cols="12" md="auto">
                <v-btn class="text-none" color="success" depressed @click="printBuildingApplication()">
                    Print Building Application <v-icon right>mdi-printer</v-icon>
                </v-btn>

            </v-col>
        </v-row>

    </v-container>

</template>