import store from "@/store/index";
import {
  GET_CUSTOMER_TYPES,
  GET_PROFILE_DATA,
  GET_USER_DATA,
  GET_SUPPORTING_FORM_DATA_REQUEST_STATUS,
  GET_SHOW_USER_DATA,
} from "@/store/getters/user";
import {
  GET_BUSINESS_FORM_DATA,
  GET_BUSINESS_FEE_DATA,
  GET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS,
} from "@/store/getters/business";
import {
  BUSINESS_FORM_DATA,
  BUSINESS_FEE_DATA,
} from "@/store/actions/business";
import { USER_DATA } from "@/store/actions/user.js";
import { SHOW_USER_DATA, USER_PROFILE } from "@/store/mutations/user";
import {
  requestStatus,
  errors,
  searchDebounceTimeInMs,
  mutationKeys,
  filterTypes,
} from "./constants";
import {
  computed,
  onMounted,
  ref,
  getCurrentInstance,
  reactive,
  watchEffect,
  watch,
  nextTick,
} from "vue";
import {
  stringNotEmpty,
  arrayNotEmpty,
  checkIsLiquorPermit,
  checkKiriBusinessExemptFromCertainFees,
  permitValueNotFalsy,
  debounce,
  downloadBlob,
  getFileDownloadUrl,
  getErrorFromResponseError,
  scrollToElement,
  removeItemFromArray,
  getVehiclesFromReceipt,
} from "./functions";
import {
  GET_COUNTY_DETAILS,
  GET_FETCH_COUNTY_DETAILS_REQUEST_STATUS,
} from "../store/getters/county";
import apiCall from "./api";
import { setSnackbar } from "@/components/utils/snackbar/logic";
import { useMutation, useQueries, useQuery } from "@tanstack/vue-query";

// County
export function getCounty() {
  return process.env.VUE_APP_COUNTY;
}

export const countyIsKilifi = computed(() => {
  return process.env.VUE_APP_COUNTY === "KILIFI";
});

export const countyIsKirinyaga = computed(() => {
  return process.env.VUE_APP_COUNTY === "KIRINYAGA";
});

export const county = {
  isKilifi: process.env.VUE_APP_COUNTY === "KILIFI",
  isKirinyaga: process.env.VUE_APP_COUNTY === "KIRINYAGA",
};

export const fetchingCountyDetails = computed(() => {
  return (
    store.getters[GET_FETCH_COUNTY_DETAILS_REQUEST_STATUS] ===
    requestStatus.SENDING
  );
});
export const countyDetails = computed(() => {
  return store.getters[GET_COUNTY_DETAILS];
});
export const countyDetailsNotEmpty = computed(() => {
  return !!countyDetails.value;
});

// Router
export function useRouter() {
  const instance = ref(null);
  const router = ref(null);
  const route = computed(() => {
    const instanceNotEmpty = instance.value !== null;
    if (instanceNotEmpty) {
      const route = instance.value.proxy.$route;
      return route;
    }
    return null;
  });
  const routeNotEmpty = computed(() => {
    return route.value !== null;
  });
  onMounted(() => {
    instance.value = getCurrentInstance();
    router.value = instance.value.proxy.$router;
  });
  return { router, route, routeNotEmpty };
}

export function useRouterFromInstance() {
  const instance = getCurrentInstance();
  return {
    router: instance.proxy.$router,
    route: computed(() => instance.proxy.$route),
  };
}

// Account creation and login
const individualCustomerTypeId = computed(() => {
  const individualCustomerType = store.getters[GET_CUSTOMER_TYPES].find(
    (customerType) => customerType.name === "Individual"
  );
  const individualCustomerTypeExists = individualCustomerType !== undefined;
  if (individualCustomerTypeExists) {
    return individualCustomerType.id;
  }
  return null;
});

export function getComputedIsIndividual(getCustomerTypeId) {
  return computed(() => {
    const individualCustomerTypeIdExists =
      individualCustomerTypeId.value !== null;
    if (individualCustomerTypeIdExists) {
      return getCustomerTypeId() === individualCustomerTypeId.value;
    }
    return null;
  });
}

export function getComputedIdOrBusinessText(getIsIndividual) {
  return computed(() => {
    const isIndividual = getIsIndividual();
    if (isIndividual) {
      return "ID";
    }
    return "Business";
  });
}

// User
export const userDataRequestStatus = ref(requestStatus.NOT_SENDING);
export const userDataLoading = computed(() => {
  return userDataRequestStatus.value === requestStatus.SENDING;
});

export const userProfile = computed(() => {
  return store.getters[GET_PROFILE_DATA];
});

export const userProfileNotEmpty = computed(() => {
  return userProfile.value !== null;
});

export const userAuthenticated = computed(() => {
  return store.getters.isAuthenticated;
});

export const userData = computed(() => {
  return store.getters[GET_USER_DATA];
});

export const supportingFormData = computed(() => {
  return store.getters.getSupportingFormData;
});

export const supportingFormDataLoading = computed(() => {
  return (
    store.getters[GET_SUPPORTING_FORM_DATA_REQUEST_STATUS] ===
    requestStatus.SENDING
  );
});

export function getWorkflowStatusFromName(statusName) {
  const workflowStatuses =
    store.getters.getSupportingFormData?.workflowStatuses;
  return (
    workflowStatuses?.find((wfStatus) => wfStatus.status_name === statusName) ??
    null
  );
}

export async function fetchUserData() {
  await store.dispatch(USER_DATA);
}

export function fetchUserDataOnMount() {
  onMounted(async () => {
    await fetchUserData();
  });
}

export function setUserProfile(userProfileData) {
  store.commit(USER_PROFILE, userProfileData);
}

export const shouldShowUserData = computed(() => {
  return store.getters[GET_SHOW_USER_DATA];
});

export function setShouldShowUserData(shouldShowUserData) {
  return store.commit(SHOW_USER_DATA, shouldShowUserData);
}

// Business
export const businessFormData = computed(() => {
  return store.getters[GET_BUSINESS_FORM_DATA];
});
export const fetchingBusinessFormData = computed(() => {
  return store.getters[GET_FETCHING_BUSINESS_FORM_DATA_REQUEST_STATUS];
});
export const businessFormDataNotEmpty = computed(() => {
  return businessFormData.value !== null;
});
export function fetchBusinessFormDataOnMount() {
  onMounted(() => {
    store.dispatch(BUSINESS_FORM_DATA);
  });
}

export const businessFeeData = computed(() => {
  return store.getters[GET_BUSINESS_FEE_DATA];
});
export const businessFeeDataNotEmpty = computed(() => {
  return businessFeeData.value !== null;
});
export function fetchBusinessFeeDataOnMount() {
  onMounted(() => {
    store.dispatch(BUSINESS_FEE_DATA);
  });
}

// Network
export function useNetworkRequest(func) {
  const networkCallStatus = ref(requestStatus.NOT_SENDING);
  const networkCallRunning = computed(() => {
    return networkCallStatus.value === requestStatus.SENDING;
  });

  async function runNetworkFunc() {
    try {
      networkCallStatus.value = requestStatus.SENDING;
      await func();
      networkCallStatus.value = requestStatus.COMPLETE;
    } catch (error) {
      networkCallStatus.value = requestStatus.ERROR;
      // throw(error)
    }
  }

  return { networkCallStatus, networkCallRunning, runNetworkFunc };
}

// Permit
export function usePermit({
  permitRef = null,
  isRenewalRef = null,
  businessRef = null,
}) {
  const permitTrueFalseItems = computed(() => {
    const permit = permitRef.value;
    const isRenewal = isRenewalRef?.value ?? false;
    const business = businessRef?.value ?? null;

    if (countyIsKilifi.value) {
      return {
        "Paying for Sanitary Inspection": permitValueNotFalsy(
          permit.sanitary_inspection
        ),
        "Paying for Food Hygiene License": permitValueNotFalsy(
          permit.food_drugs_sale
        ),
        "Paying for Advertisement": permitValueNotFalsy(
          permit.advertisement_fee
        ),
        "Paying for Solid Waste Management": permitValueNotFalsy(
          permit.solid_waste_fee
        ),
        "Paying for Betting Control and Licensing": permitValueNotFalsy(
          permit.betting_fee
        ),
      };
    } else if (countyIsKirinyaga.value) {
      const isLiquorBusiness = checkIsLiquorPermit(permit);
      const isExemptFromCertainFees =
        checkKiriBusinessExemptFromCertainFees(business);
      const isPayingMandatoryFees =
        !isLiquorBusiness && !isExemptFromCertainFees;
      let items = {};

      // const applicationFee = getSbpApplicationFee(business, businessFormData.value)
      // const applicationFeeNotEmpty = !!applicationFee
      // if (applicationFeeNotEmpty) {
      //     const applicationFeeTitle = `${applicationFee?.fee_name} Fee`
      //     items = {
      //         [applicationFeeTitle]: !isLiquorBusiness
      //     }
      // }

      items = {
        ...items,
        "Paying for Liquor": isLiquorBusiness,
        // 'Paying for Liquor Public Health Fee': isLiquorBusiness,
        // 'Paying for Registration Fee': !isLiquorBusiness && !isRenewal,
        // 'Paying for Renewal Fee': !isLiquorBusiness && isRenewal,
        "Paying for Public Health Certificate": permitValueNotFalsy(
          permit.public_health_certificate
        ),
        "Paying for Public Health Inspections": permitValueNotFalsy(
          permit.public_health_inspection
        ),
        "Paying for Conservancy Fee": permitValueNotFalsy(
          permit.conservancy_fee
        ),
        "Paying for Advertisement Fee": permitValueNotFalsy(
          permit.advertisement_fee
        ),
        "Paying for Sublet Fee":
          !isRenewal && permitValueNotFalsy(permit.sublet),
        "Paying for Ownland Fee": permitValueNotFalsy(permit.ownland),
      };
      return items;
    }
  });

  return { permitTrueFalseItems };
}

// Sub county and ward
export function useSubcountyWard() {
  const locationData = reactive({
    subcountyId: null,
    wardId: null,
    townId: null,
  });

  const locationDataLoading = computed(() => {
    return fetchingBusinessFormData.value === requestStatus.SENDING;
  });

  const subcounties = computed(() => businessFormData.value?.subCounty ?? null);

  watchEffect(() => {
    const subcountySelected = !!locationData.subcountyId;
    if (subcountySelected) {
      const selectedSubcounty = subcounties.value.find(
        (subcounty) => subcounty.id === locationData.subcountyId
      );
      const wardsToShow = selectedSubcounty?.wards ?? null;
      wards.value = wardsToShow;
      towns.value = null;
      locationData.wardId = null;
      locationData.townId = null;
    }
  });

  watchEffect(() => {
    const wardSelected = !!locationData.wardId;
    if (wardSelected) {
      const selectedWard = wards.value.find(
        (ward) => ward.id === locationData.wardId
      );
      const townsToShow = selectedWard?.towns ?? null;
      towns.value = townsToShow;
      locationData.townId = null;
    }
  });

  const wards = ref(null);
  const towns = ref(null);

  return { subcounties, wards, towns, locationData, locationDataLoading };
}

// Pagination
export function usePaginatedData() {
  const paginationData = reactive({
    fetchedData: [],
    fetchedDataNotEmpty: false,
    paginationData: {
      currentPage: 1,
      perPage: 0,
      total: 0,
      visible: 10,
    },
    fetchDataRequestStatus: requestStatus.NOT_SENDING,
    fetchingData: false,
    fetchData: null,
    setNewPage: null,
  });

  function setPaginationData(serverResponse) {
    paginationData.paginationData.currentPage = serverResponse.current_page;
    paginationData.paginationData.total = serverResponse.total;
    paginationData.paginationData.perPage = serverResponse.per_page;
  }

  function setNewPage(newPage) {
    paginationData.paginationData.currentPage = newPage;
  }

  async function fetchData(url) {
    try {
      paginationData.fetchDataRequestStatus = requestStatus.SENDING;
      paginationData.fetchingData = true;
      const response = await apiCall({
        url,
        method: "GET",
      });
      const responseData = response.data;
      paginationData.fetchedDataNotEmpty = arrayNotEmpty(responseData);
      paginationData.fetchedData = responseData;

      setPaginationData(response);
      paginationData.fetchDataRequestStatus = requestStatus.COMPLETE;
      paginationData.fetchingData = false;
    } catch (error) {
      paginationData.fetchDataRequestStatus = requestStatus.ERROR;
      paginationData.fetchingData = false;
      throw error;
    }
  }

  paginationData.fetchData = fetchData;
  paginationData.setNewPage = setNewPage;

  return {
    paginationData,
  };
}


export function usePaginatedDataWithDebouncedSearchStrucEng() {
  const searchModel_strucEngs = ref("");
  const debouncedSearchModel = ref(null);
  const debouncedSearchModelNotEmpty = computed(() => {
    return stringNotEmpty(debouncedSearchModel.value);
  });

  const { paginationData } = usePaginatedData();

  const setDebouncedSearch = debounce((searchValue) => {
    paginationData.setNewPage(1);
    debouncedSearchModel.value = searchValue;
  }, searchDebounceTimeInMs);

  watch(searchModel_strucEngs, (newVal) => {
    setDebouncedSearch(newVal);
  });

  return {
    paginationData,
    searchModel_strucEngs,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  };
}

export function usePaginatedDataWithDebouncedSearchArchitect() {
  const searchModel_architects = ref("");
  const debouncedSearchModel = ref(null);
  const debouncedSearchModelNotEmpty = computed(() => {
    return stringNotEmpty(debouncedSearchModel.value);
  });

  const { paginationData } = usePaginatedData();

  const setDebouncedSearch = debounce((searchValue) => {
    paginationData.setNewPage(1);
    debouncedSearchModel.value = searchValue;
  }, searchDebounceTimeInMs);

  watch(searchModel_architects, (newVal) => {
    setDebouncedSearch(newVal);
  });

  return {
    paginationData,
    searchModel_architects,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  };
}

export function usePaginatedDataWithDebouncedSearchClient() {
  const searchModel_clients = ref("");
  const debouncedSearchModel = ref(null);
  const debouncedSearchModelNotEmpty = computed(() => {
    return stringNotEmpty(debouncedSearchModel.value);
  });

  const { paginationData } = usePaginatedData();

  const setDebouncedSearch = debounce((searchValue) => {
    paginationData.setNewPage(1);
    debouncedSearchModel.value = searchValue;
  }, searchDebounceTimeInMs);

  watch(searchModel_clients, (newVal) => {
    setDebouncedSearch(newVal);
  });

  return {
    paginationData,
    searchModel_clients,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  };
}

export function usePaginatedDataWithDebouncedSearch() {
  const searchModel = ref("");
  const debouncedSearchModel = ref(null);
  const debouncedSearchModelNotEmpty = computed(() => {
    return stringNotEmpty(debouncedSearchModel.value);
  });

  const { paginationData } = usePaginatedData();

  const setDebouncedSearch = debounce((searchValue) => {
    paginationData.setNewPage(1);
    debouncedSearchModel.value = searchValue;
  }, searchDebounceTimeInMs);

  watch(searchModel, (newVal) => {
    setDebouncedSearch(newVal);
  });

  return {
    paginationData,
    searchModel,
    debouncedSearchModel,
    debouncedSearchModelNotEmpty,
  };
}

// export function usePaginatedData() {
//     const paginationData = reactive({
//         currentPage: 1,
//         perPage: 0,
//         total: 0,
//         visible: 10
//     })

//     function setPaginationData(serverResponse) {
//         paginationData.currentPage = serverResponse.current_page
//         paginationData.total = serverResponse.total
//         paginationData.perPage = serverResponse.per_page
//     }

//     function setNewPage(newPage) {
//         paginationData.paginationData.currentPage = newPage
//     }

//     async function fetchData({ queryKey }) {
//         const [url] = queryKey
//         try {
//             const response = await apiCall({
//                 url,
//                 method: 'GET'
//             })
//             setPaginationData(response)
//             return response.data
//         } catch (error) {
//             throw (error)
//         }
//     }

//     const url = ref(null)

//     const paginatedDataQuery = useQuery({
//         queryKey: [url],
//         queryFn: fetchData,
//         keepPreviousData: true
//     })

//     paginatedDataQuery.paginationData = paginationData
//     paginatedDataQuery.url = url
//     paginatedDataQuery.setNewPage = setNewPage

//     return paginatedDataQuery
// }

export function useMakeNetworkRequest() {
  const networkRequestStatus = ref(requestStatus.NOT_SENT);
  const requestLoading = computed(() => {
    return networkRequestStatus.value === requestStatus.SENDING;
  });

  async function makeNetworkRequest(apiCallData) {
    try {
      networkRequestStatus.value = requestStatus.SENDING;
      const response = await apiCall(apiCallData);
      networkRequestStatus.value = requestStatus.COMPLETE;
      return response;
    } catch (error) {
      networkRequestStatus.value = requestStatus.ERROR;
      throw error;
    }
  }

  return {
    networkRequestStatus,
    requestLoading,
    makeNetworkRequest,
  };
}

export function useNetworkRequestLoading(networkRequestStatusRef) {
  const loading = computed(() => {
    return networkRequestStatusRef.value === requestStatus.SENDING;
  });

  return { loading };
}

export function showSnackbarForResponseError(error) {
  switch (error) {
    case errors.PROPERTY_NOT_FOUND:
      setSnackbar({
        text: "Property Not Found",
        color: "error",
        open: true,
      });
      break;
    default:
      setSnackbar({
        text: "An error occurred",
        color: "error",
        open: true,
      });
  }
}

export function useDownloadFile() {
  async function fetchFile({ url, snackbarText }) {
    try {
      setSnackbar({
        text: snackbarText,
        color: "success",
        open: true,
      });
      const file = await apiCall({
        url,
        method: "GET",
        responseType: "blob",
      });
      return file;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const file = useMutation({
    mutationFn: fetchFile,
    mutationKey: [mutationKeys.DOWNLOAD_FILE],
    onSuccess(data, { filename }) {
      downloadBlob({ blob: data, filename });
    },
  });

  function downloadFile({
    billId,
    billNumber,
    receiptNumber,
    propertyRateData,
    propertySearchCertPrn,
    vehicleStickerIds,
    shouldDownloadVehicleList,
    vehicles
  }) {
    let url;
    let filename = "file";
    let snackbarText = "Downloading";
    if (!!billId) {
      url = getFileDownloadUrl({ billId });
      filename = `Bill_${billNumber}`;
      snackbarText += " Bill";
    }
    if (!!receiptNumber) {
      url = getFileDownloadUrl({ receiptNumber });
      filename = `Receipt_${receiptNumber}`;
      snackbarText += " Receipt";
    }
    if (!!propertyRateData) {
      url = getFileDownloadUrl({ propertyRateData });
      filename = `Property_Rates_${propertyRateData.prn}_${propertyRateData.fromDate}_${propertyRateData.toDate}`;
      snackbarText += " Rate Statement";
    }
    if (!!propertySearchCertPrn) {
      url = getFileDownloadUrl({ propertySearchCertPrn });
      filename = "eportal_property_search_certificate";
      snackbarText += " Search Certificate";
    }
    if (!!vehicleStickerIds) {
      url = getFileDownloadUrl({ vehicleStickerIds });
      filename = `Vehicle_Sticker`;
      snackbarText += " Vehicle Sticker";
    }
    if (!!vehicles) {
      url = getFileDownloadUrl({ vehicles });
      filename = `Vehicle_Sticker`;
      snackbarText += " Vehicle Stickers";
    }
    if (!!shouldDownloadVehicleList) {
      url = getFileDownloadUrl({ shouldDownloadVehicleList });
      filename = "Vehicles";
      snackbarText += " Vehicles";
    }
    file.mutate({ url, filename, snackbarText });
  }

  return { file, downloadFile };
}

// Postal code
export function usePostalCodeFromPostalTown({ postalTownRef }) {
  const postalCode = computed(() => {
    const selectedPostalTown = postalTownRef.value;
    const selectedPostalTownNotEmpty = stringNotEmpty(selectedPostalTown);
    const postalTownsList = supportingFormData.value.postalTowns;
    const postalTownsListNotEmpty =
      postalTownsList !== null && postalTownsList !== undefined;
    if (selectedPostalTownNotEmpty && postalTownsListNotEmpty) {
      const postalTown = postalTownsList.find(
        (postalTown) => postalTown.postal_town === selectedPostalTown
      );
      return postalTown.postal_code;
    }
    return "";
  });

  return { postalCode };
}

export function useValidationErrors() {
  const responseError = ref(null);
  const errorItems = ref({});

  watch(responseError, (val) => {
    setErrors(val);
  });

  function addErrorItem({ errorKey, clearOnChangeFn }) {
    errorItems.value = {
      ...errorItems.value,
      [errorKey]: {
        clearOnChangeFn,
        errorValue: null,
      },
    };
  }

  function setErrors(responseError) {
    const error = getErrorFromResponseError(responseError);
    const validationErrors = error?.data?.errors ?? {};
    Object.entries(validationErrors).forEach(([errorKey, errorMsg]) => {
      // const itemToSetErrorOn = errorItems.value.find(item => item.errorKey === errorKey)
      // itemToSetErrorOn.errorValueRef.value = errorMsg
      const itemToSetErrorOn = errorItems.value?.[errorKey];
      const itemExists = !!itemToSetErrorOn;
      if (itemExists) {
        errorItems.value[errorKey].errorValue = errorMsg;
      }
    });
  }

  function setWatchersForClearOnChange() {
    Object.entries(errorItems.value).forEach(([errorKey, errorItem]) => {
      const hasClearOnChange = !!errorItem.clearOnChangeFn;
      if (hasClearOnChange) {
        watch(
          computed(() => errorItem.clearOnChangeFn()),
          () => {
            const error = errorItems.value[errorKey].errorValue;
            const hasError = arrayNotEmpty(error) || !!error;
            if (hasError) {
              // errorItem.errorValue = null
              errorItems.value[errorKey].errorValue = null;
            }
          }
        );
      }
    });
  }

  return {
    addErrorItem,
    setWatchersForClearOnChange,
    responseErrorRef: responseError,
    errorItems,
  };
}

export function useGenerateBill() {
  async function generateBill({
    billItems,
    remarks = null,
    subcountyId = null,
    wardId = null,
    townId = null,
  }) {
    const requestData = {
      bill_items: billItems,
      sub_county_id: subcountyId,
      ward_id: wardId,
      town_id: townId,
      remarks: remarks,
    };
    try {
      const response = await apiCall({
        url: "/api/client/bill",
        data: requestData,
        method: "POST",
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const generateBillMtn = useMutation({
    mutationFn: generateBill,
  });

  return generateBillMtn;
}

export function useSetToUpperCase({ textRef, setText }) {
  watch(textRef, (newVal) => {
    const uppercaseText = newVal.toUpperCase();
    const shouldUpdateTextRef = uppercaseText !== newVal;
    if (shouldUpdateTextRef) {
      setText(uppercaseText);
    }
  });
}

export function getTownFromSubcountyAndWard({ subcountyId, wardId, townId }) {
  const subcounties = supportingFormData.value.subCounties;
  const subcountyIdEmpty = !!!subcountyId;
  const wardIdEmpty = !!!wardId;
  if (subcountyIdEmpty || wardIdEmpty) {
    return null;
  }
  const town = subcounties
    ?.find((subcounty) => subcounty.id === subcountyId)
    ?.wards.find((ward) => ward.id === wardId)
    ?.towns.find((town) => town.id === townId);
  return town?.town_name;
}

export function scrollToError({
  containerElement,
  couldBePhoneNumberError = false,
  errorClass = ".v-messages.error--text",
  elementToScrollTo = ".v-input",
}) {
  nextTick(() => {
    const errorElement = containerElement.querySelector(`${errorClass}`);
    let scrollTo = errorElement.closest(elementToScrollTo);
    if (!scrollTo && couldBePhoneNumberError) {
      scrollTo = errorElement.closest(".phone-number-input");
    }
    if (scrollTo) {
      scrollToElement(scrollTo);
    }
  });
}

export function useGetVisitorData() {
  async function getVisitorCount() {
    const url = "/api/client/visitor-count";
    try {
      const response = await apiCall({
        url,
        method: "GET",
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const visitorDataQuery = useQuery({
    queryKey: ["visitorData"],
    queryFn: getVisitorCount,
    refetchOnWindowFocus: false,
  });

  return visitorDataQuery;
}

export function useCallSpringUrl() {
  async function callSpringUrl({ url, method, data }) {
    const requestData = { url, method, data };
    try {
      const response = await apiCall({
        url: "/api/client/call-spring-url",
        data: requestData,
        method: "POST",
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  const callSpringUrlMutation = useMutation({
    mutationFn: callSpringUrl,
  });

  return { callSpringUrlMutation };
}

export function useFilters(filtersRef) {
  const filterValuesForDisplay = computed(() => {
    let filterValues = [];
    filtersRef.value.forEach((filter) => {
      switch (filter.type) {
        case filterTypes.array:
          if (!!filter.value) {
            filterValues = [
              convertArrayFilterValueForDisplay(filter),
              ...filterValues,
            ];
          }
          break;
      }
    });
    return filterValues;
  });

  function clearAllFilters() {
    if (arrayNotEmpty(filterValuesForDisplay.value)) {
      filterValuesForDisplay.value.forEach((filterValueForDisplay) => {
        filterValueForDisplay.removeFilterFn();
      });
    }
  }

  const atLeastOneFilterActive = computed(() => {
    return arrayNotEmpty(filterValuesForDisplay.value);
  });

  function convertArrayFilterValueForDisplay(filter) {
    return {
      color: filter.color,
      value: filter.value,
      removeFilterFn: () => {
        // removeItemFromArray(filterValue, filter.value);
        filter.value = "";
      },
    };

    // return filter.value.map((filterValue) => ({
    //   color: filter.color,
    //   value: filterValue,
    //   removeFilterFn: () => {
    //     removeItemFromArray(filterValue, filter.value);
    //   },
    // }));
  }

  function appendArrayFilterValueToString(filter) {
    if (arrayNotEmpty(filter.value)) {
      return `${filter.requestName}=${filter.value.join(",")}`;
    }
    return "";
  }

  const filterString = computed(() => {
    const searchStrings = [];
    filtersRef.value.forEach((filter) => {
      switch (filter.type) {
        case filterTypes.array:
          // searchStrings.push(appendArrayFilterValueToString(filter));
          if (!!filter.value) {
            searchStrings.push(`${filter.requestName}=${filter.value}`);
          }
          break;
      }
    });

    return searchStrings
      .filter((searchString) => searchString !== "")
      .join("&");
  });

  return {
    filterValuesForDisplay,
    filterString,
    filtersRef,
    clearAllFilters,
    atLeastOneFilterActive,
  };
}

export function getVueInstance() {
  const vue = getCurrentInstance();
  return { vue, vuetify: vue.proxy.$vuetify };
}

export function useVehiclesFromReceipt(receipt) {
  const vehicles = computed(() => getVehiclesFromReceipt(receipt));
  const receiptHasVehicles = computed(() => {
    return arrayNotEmpty(vehicles.value);
  });
  return { vehicles, receiptHasVehicles };
}
