<script setup>
import { setupInformation } from "./buildings_business_logic";
import { onMounted } from "vue";

import BackButton from "@/components/utils/BackButton.vue";
import NextButton from '@/components/utils/NextButton.vue';

const { selected_item, instruction_list } = setupInformation()

onMounted(() => {
    console.log("instruction_list")
    console.log(instruction_list)

});

function downloadInstructionDocument(){
    
}

</script>

<template>
    <v-container fluid class="pa-0">

        <v-row>
            <v-col cols="12" md="1">
                <BackButton @click="$emit('goBackClicked')">
                Back To Buildings
                </BackButton>
            </v-col>

        </v-row>

        


        <h2 class="text-h5">BUILDING PLANS APPLICATION (BPA) - APPLICATION INSTRUCTIONS</h2>

        <div class="d-flex justify-end mb-n5">
            <v-btn depressed rounded class="white--text" color="success"
                @click="downloadInstructionDocument">
                <span>Download Instruction Document</span><v-icon right>mdi-download</v-icon>
            </v-btn>

        </div>


        <v-list style="background-color: transparent;" dense>
            <v-subheader class="text-h5">Requirements matrix</v-subheader>
            <v-list-item-group v-model="selected_item" color="primary">
                <v-list-item v-for="(item, i) in instruction_list" :key="i">
                    <v-list-item-content>
                        <v-list-item-title>{{ (i + 1) +'. '+ item.text }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>


        <p>The County Government Act (2012), the Physical and Land Use Planning Act 2019 (No 13 of 2019), the Public
            Health Act (Cap 242), the Urban Areas and Cities Act (2011), Environmental Management and Co-ordination Act
            (1999) and the Building Code (2009) To: County Executive Committee Member - Lands and Physical Planning P.O.
            Box 11-01100, Kajiado.</p>

        <p>Important Notes/Requirements a) Structural plan be submitted after architectural plans are approved. These
            plans must be approved before commencement of construction work b) For extensions and alterations, all new
            works must be coloured in red, this include walls and floor slabs. Any demolition/deletion to be coloured
            yellow.</p>

        <p>Only coloured pencils to be used. c) The following copies to be attached - Certified copies of ownership
            documents (title deed, certificate of lease, letter of allotment) - County government payment receipt(s) - A
            survey plan/mutation - A detailed location plan for ease of reference - Approval for change of user (form
            PLUPA/DC/8) where applicable - Certified Certificate of Incorporation in case of company - Valid official
            search issued not more than twenty eight (28) days before date of application d) Tenants can only make
            alternations and/or extensions to a building with written authority from the owner/s. This authority to be
            attached. e) Developers undertaking any type of construction work are cautioned against the danger of
            interfering with all types of underground or overhead cables and services without consulting the relevant
            authorities in the first instance. f) Occupation certificates must be obtained from the County Government
            before occupying any space of the building. g) All conditions for approval must be complied with as
            stipulated/attached. </p>



        <v-row justify="center" justify-md="end">
            <!-- <v-col cols="auto">
                <v-btn depressed rounded class="button-background-secondary-1 white--text"
                    @click="onDownloadVehicleStickersClicked">
                    <span>Download Instruction Document</span><v-icon right>mdi-download</v-icon>
                </v-btn>
            </v-col> -->

            <v-col>

                <NextButton @click="$emit('goNextSection')">
                    <template #loadingText> Please Wait </template>
                </NextButton>

                <!-- <v-btn @click="$emit('goNextSection')" depressed rounded class="button-background-secondary-1 white--text">
                    Next
                    <v-icon class="white--text" right>
                        mdi-chevron-right
                    </v-icon>
                </v-btn> -->

            </v-col>
        </v-row>







    </v-container>


</template>