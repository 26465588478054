var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Table,{attrs:{"headers":[
    { text: 'PRN No', value: 'prn' },
    { text: 'Block / Plot Number', value: 'block_plot_no' },
    { text: 'Sub County', value: 'sub_county' },
    { text: 'Status', value: 'status' },
    { text: 'Outstanding Amount', value: 'outstanding_amount' },
    { text: 'View', value: 'view', action: true },
    { text: _vm.componentAction ? 'Remove Property' : 'Add Property', value: 'add_property', action: true },
  ],"items":_vm.properties,"loading":_vm.loading,"emptyText":"No plots to show","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.block_plot_no",fn:function({ item: property }){return [_vm._v(" "+_vm._s(property.block_no)+"/"+_vm._s(property.plot_no)+" ")]}},{key:"item.sub_county",fn:function({ item: property }){return [_vm._v(" "+_vm._s(property.sub_county.sub_county_name)+" ")]}},{key:"item.status",fn:function({ item: property }){return [_vm._v(" "+_vm._s(property.status.status_name)+" ")]}},{key:"item.outstanding_amount",fn:function({ item: property }){return [_vm._v(" "+_vm._s(_setup.getFormattedMoney(property?.rates?.current_balance))+" ")]}},{key:"item.view",fn:function({ item: property }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.view,"inTable":""},on:{"click":function($event){return _vm.$emit('viewPropertyClicked', property)}}})]}},(_vm.componentAction == 'Remove Item')?{key:"item.add_property",fn:function({ item: property }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.removeItem,"text":"Remove Property","inTable":""},on:{"click":function($event){return _vm.$emit('addPropertyToApplication', property)}}})]}}:{key:"item.add_property",fn:function({ item: property }){return [_c(_setup.ActionButton,{attrs:{"text":"Add Property","action":_setup.actions.addBuilding,"inTable":""},on:{"click":function($event){return _vm.$emit('addPropertyToApplication', property)}}})]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }