export const requestStatus = {
  NOT_SENDING: "NOT_SENDING",
  SENDING: "SENDING",
  COMPLETE: "COMPLETE",
  ERROR: "ERROR",
  CANCELLED: "CANCELLED",
};

export const otpStatus = {
  NOT_VALIDATED: "NOT_VALIDATED",
  OTP_INVALID: "OTP_INVALID",
  OTP_VALID: "OTP_VALID",
};

export const INVALID_OTP = "invalid otp";

export const ACCOUNT_NOT_FOUND = "account not found";

export const INVALID_TOKEN = "invalid token";

export const paymodes = {
  cash: "Cash",
  directBanking: "Direct Banking",
  cheque: "Cheque",
  mobileMoney: "Mobile Money",
};

export const paymentErrors = {
  transactionNotFound: "TRANSACTION_NOT_FOUND",
  amountPaidNotSufficient: "AMOUNT_PAID_NOT_SUFFICIENT",
  stkPushFailed: "STK_PUSH_FAILED",
  bankPaymentError: "BANK_PAYMENT_ERROR"
};

export const examplePhoneNumber = "765329764";

export const passwordSpecialCharacters = "!@#$%.";

export const searchDebounceTimeInMs = 1000;

export const errors = {
  PROPERTY_NOT_FOUND: "property not found",
  PROPERTY_SEARCH_FEES_NOT_CONFIGURED:
    "property search subpart fee not fully configured",
};

export const mutationKeys = {
  DOWNLOAD_FILE: "DOWNLOAD_FILE",
};

export const fileTypes = {
  BILL: "BILL",
  RECEIPT: "RECEIPT",
};

export const services = {
  solidWaste: "solid_waste",
  noisePollution: "noise_pollution",
  documentaryServices: "documentary_services",
  countyHiringServices: "county_hiring_services",
  generalServices: "general_services",
  physicalPlanning: "physical_planning",
  fireSafety: "fire_safety",
};

export const checkAccountStatus = {
  accountActivated: "account activated",
};

export const actions = {
  view: "view",
  download: "download",
  downloadBill: "downloadBill",
  downloadReceipt: "downloadReceipt",
  downloadVehicleSticker: "downloadVehicleSticker",
  downloadVehicleList: 'downloadVehicleList',
  pay: "pay",
  canSelect: "canSelect",
  canUnselect: "canUnselect",
  renewPermit: "renewPermit",
  payPropertyRates: "payPropertyRates",
  addBuilding: "addBuilding",
  addClient: "addClient",
  removeClient: "removeClient",
  removeItem:"removeItem"
};

export const filterTypes = {
  string:'string',
  array:'array'
}

export const vehicleQueryKeys = {
  formData: ['vehicle-form-data']
}