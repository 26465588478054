<script setup>
import ViewItemSectionTitle from "@/components/utils/ViewItemSectionTitle.vue";
import { getFormattedMoney } from "@/utils/functions";
import { routes } from "@/router/routes";
import { useRouter } from "@/utils/vue_helpers";
import { computed, onMounted } from "vue";


const props = defineProps({
  property: {
    type: Object,
    default: null,
  },
  ratesBillSectionLoading: {
    type: Boolean,
    default: false,
  },
  moduleInUse:{
    type: String,
    default: '',
  }
});

const propertyRateItems = computed(() => {
  const rates = props.property?.rates ?? null;
  const ratesNotEmpty = !!rates;
  if (ratesNotEmpty) {
    return {
      "Annual Rate": rates.annual_rates,
      "Annual Rate Arrears": rates.annual_rates_arrears,
      "Ground Rent": rates.ground_rent,
      "Ground Rent Arrears": rates.ground_rent_arrears,
      "Other Charges": rates.other_charges,
      "Other Charges Arrears": rates.other_charges_arrears,
      "Accumulated Penalty": rates.accumulated_penalty,
    };
  }
  return null;
});

const propertyRateItemsNotEmpty = computed(() => {
  return !!propertyRateItems.value;
});

const { router } = useRouter();

function onViewRateStatementClicked() {
  const route = routes.propertyRateStatement.withQuery({
    prn: props.property.prn,
  });
  router.value.push(route);
}

onMounted(() => {
  console.log("props.moduleInUse")
  console.log(props.moduleInUse)

  
})
</script>

<template>
  <v-container fluid class="pa-0">
    <v-row justify="space-between" align="center">
      <v-col cols="auto">
        <ViewItemSectionTitle>Property Rates</ViewItemSectionTitle>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Rates</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>

            <tbody>
              <template v-if="propertyRateItemsNotEmpty">
                <tr v-for="(value, title) in propertyRateItems">
                  <td>{{ title }}</td>
                  <td class="text-right">{{ getFormattedMoney(value) }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Current Balance</td>
                  <td class="text-right font-weight-bold">
                    {{ getFormattedMoney(property.rates.current_balance) }}
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="2" align="center">No Property Rates</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row v-if="propertyRateItemsNotEmpty" justify="space-between">
      <v-col cols="auto">
        <v-btn
          depressed
          color="secondary"
          outlined
          class="text-none"
          @click="onViewRateStatementClicked"
        >
          View Rates Statement
          <v-icon right> mdi-file-chart </v-icon>
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
          class="secondary text-none"
          :loading="ratesBillSectionLoading"
          @click="$emit('generateRatesBillClicked')"
        >
          Pay Property Rates
          <v-icon right>mdi-credit-card-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
