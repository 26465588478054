<script setup>
import { isFn, arrayNotEmpty, lastInList } from "@/utils/functions";
import { onMounted, ref } from "vue";

import { attachedDocumentsList } from "@/components/buildings/buildings_business_logic.js";


const { attached_documents, attached_documents_with_share_certificate, attachedDocumentsModel } = attachedDocumentsList()

let my_documents = ref(attached_documents)

const props = defineProps({
    headers: {
        type: Array,
        default: null,
    },
    items: {
        type: Array,
        default: null,
    },
    headClasses: {
        type: String,
        default: "",
    },
    headStyles: {
        type: Object,
        default: () => ({}),
    },
    bodyClasses: {
        type: String,
        default: "",
    },
    bodyStyles: {
        type: Object,
        default: () => ({}),
    },
    loading: {
        type: Boolean,
        default: false,
    },
    dataBeingHandled: {
        type: String,
        default: "",
    },
    emptyText: {
        type: String,
        default: "No items found",
    },
    dense: {
        type: Boolean,
        default: false,
    },
});

function getCellValue(header, item) {
    if (isFn(header.fn)) {
        return header.fn(item);
    } else {
        return item?.[header.value];
    }
}

function checkIfDocumentIsNull(data) {
    console.log("header_header_header")
    console.log(data)
    if (data.type != undefined) {
        return true;
    }
    return false;

}

onMounted(() => {
    console.log("dataBeingHandled_dataBeingHandled")
    console.log(props.dataBeingHandled)

});

</script>

<template>
    <div v-if="$mobileBreakpoint">
        <template v-if="arrayNotEmpty(items)">
            <v-card v-for="(item, index) in items" :key="index" outlined :class="{
                'mt-4': index !== 0,
            }">
                <v-card-title v-if="$scopedSlots.itemTitle"
                    :class="{ 'text-body-1 font-weight-bold': $mobileBreakpoint }">
                    <slot name="itemTitle" :item="item"></slot>
                </v-card-title>

                <v-simple-table :dense="dense">

                    <template #default>
                        <tbody>
                            <template v-for="(header, index) in items">
                                <tr v-if="checkIfDocumentIsNull(header)" :key="index">

                                    <td class="text-caption" :style="{ color: 'inherit', fontSize: 'inherit' }">
                                        {{ index + 1 }}
                                    </td>

                                    <td class="text-caption">
                                        {{ my_documents[rowIndex] }}
                                    </td>

                                    <td class="text-caption">
                                        {{ header.name }}
                                    </td>

                                    <td class="text-caption">
                                        {{ header.type }}
                                    </td>

                                </tr>
                            </template>
                        </tbody>
                    </template>

                </v-simple-table>
            </v-card>
        </template>
        <div v-else class="text-center text-body-2">
            {{ loading ? "Loading" : emptyText }}
        </div>
    </div>

    <v-simple-table v-else :dense="dense">
        <template #default>
            <thead :class="['table-header-text', headClasses]" :style="[{ fontSize: '12px' }, headStyles]">
                <tr>
                    <th v-for="(header, index) in headers" :key="index"
                        :style="{ color: 'inherit', fontSize: 'inherit' }" :class="{
                            'text-md-center': header.action === true,
                            'pr-0': lastInList(index, headers),
                            [header.textAlign]: !!header.textAlign,
                        }">
                        <slot :name="`header.${header.value}`" :header="header">
                            {{ header.text }}
                        </slot>
                    </th>
                </tr>
            </thead>

            <tbody :class="[bodyClasses]" :style="[{ fontSize: '14px' }, bodyStyles]">

                <template v-if="arrayNotEmpty(items)">
                    <template v-for="(item, rowIndex) in items">
                        <tr v-if="checkIfDocumentIsNull(item)" :key="rowIndex">
                            <td
                                :style="{ color: 'inherit', fontSize: 'inherit' }">
                                {{ rowIndex + 1 }}
                            </td>

                            <td>
                                {{ my_documents[rowIndex] }}
                            </td>

                            <td>
                                {{ item.name }}
                            </td>

                            <td>
                                {{ item.type }}
                            </td>
                        </tr>

                    </template>

                </template>

                <slot name="additionalRows"></slot>
            </tbody>
        </template>
    </v-simple-table>
</template>
