var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"timeout":_setup.snackbar_info.snackbar_timeout,"top":"","color":_setup.snackbar_info.color},model:{value:(_setup.snackbar_info.snackbar),callback:function ($$v) {_vm.$set(_setup.snackbar_info, "snackbar", $$v)},expression:"snackbar_info.snackbar"}},[_c('span',[_vm._v(_vm._s(_setup.snackbar_info.snackbar_message))])]),_c('v-stepper',{staticClass:"elevation-0",attrs:{"vertical":""},model:{value:(_setup.stepper_position),callback:function ($$v) {_setup.stepper_position=$$v},expression:"stepper_position"}},[_c('v-stepper-step',{attrs:{"complete":_setup.stepper_position > 1,"step":"1"}},[_vm._v(" Add Client "),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 text-center font-weight-bold primary--text"},[_vm._v(" Clients ")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c(_setup.LabelledField,{attrs:{"label":"Search"}},[_c('v-text-field',{staticClass:"background text-field",attrs:{"outlined":"","dense":"","clearable":""},model:{value:(_setup.searchModel_clients),callback:function ($$v) {_setup.searchModel_clients=$$v},expression:"searchModel_clients"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.LoadingArea,{attrs:{"loading":_setup.clientsData.fetchingData}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
                                        {
                                            text: 'Customer Name',
                                            value: 'customer_name',

                                        },

                                        {
                                            text: 'Phone No',
                                            value: 'phone_number1',

                                        },

                                        {
                                            text: 'ID No',
                                            value: 'id_number',

                                        },

                                        {
                                            text: 'Client Type',
                                            value: 'client_type',


                                        },

                                        { text: 'Add Building', value: 'add_building', action: true },

                                    ],"items":_setup.clientsData.fetchedData,"loading":_setup.clientsData.fetchingData,"emptyText":"No Clients Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.add_building",fn:function({ item: client }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.addClient,"inTable":""},on:{"click":function($event){return _setup.attachClient(client)}}})]}}])})],1)],1)],1)],1)],1)],1),_c('v-expand-transition',[(_setup.create_building_aplication_data.clients_array_data.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Attached Client")])]),_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
                                        {
                                            text: 'Customer Name',
                                            value: 'customer_name',

                                        },

                                        {
                                            text: 'Phone No',
                                            value: 'phone_number1',

                                        },

                                        {
                                            text: 'ID No',
                                            value: 'id_number',

                                        },

                                        {
                                            text: 'Client Type',
                                            value: 'client_type',


                                        },

                                        { text: 'Remove Building', value: 'remove_building', action: true },

                                    ],"items":_setup.create_building_aplication_data.clients_array_data,"emptyText":"No Clients Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.remove_building",fn:function({ item: client }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.removeClient,"inTable":""},on:{"click":function($event){return _setup.onRemoveClientFromApplication()}}})]}}],null,false,2199331672)})],1)],1)],1)],1)],1):_vm._e()],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_setup.changeStepA}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_setup.prevStepA}},[_vm._v(" Previous ")])],1),(!(_setup.store.getters[_setup.GET_USER_DATA].profile.role_name == 'County Architect'))?[_c('v-stepper-step',{attrs:{"complete":_setup.stepper_position > 2,"step":"2"}},[_vm._v(" Add Architect "),_c('span',{staticClass:"error--text"},[_vm._v("*")])]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 text-center font-weight-bold primary--text"},[_vm._v(" Architects ")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c(_setup.LabelledField,{attrs:{"label":"Search"}},[_c('v-text-field',{staticClass:"background text-field",attrs:{"outlined":"","dense":"","clearable":""},model:{value:(_setup.searchModel_architects),callback:function ($$v) {_setup.searchModel_architects=$$v},expression:"searchModel_architects"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.LoadingArea,{attrs:{"loading":_setup.architectsData.fetchingData}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
                                            {
                                                text: 'Architect Name',
                                                value: 'customer_name',
                                                fn: (architectsData) => architectsData.first_name ? architectsData.first_name + ' ' + architectsData.last_name : architectsData.organisation_name

                                            },

                                            {
                                                text: 'Architect No',
                                                value: 'architect_number',

                                            },

                                            {
                                                text: 'Telephone No',
                                                value: 'phone_number',

                                            },

                                            {
                                                text: 'Email',
                                                value: 'email_address',


                                            },

                                            { text: 'Add Architect', value: 'add_architect', action: true },

                                        ],"items":_setup.architectsData.fetchedData,"loading":_setup.architectsData.fetchingData,"emptyText":"No Architects Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.add_architect",fn:function({ item: architect }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.addClient,"text":"Add Architect","inTable":""},on:{"click":function($event){return _setup.attachArchitect(architect)}}})]}}],null,false,1723993651)})],1)],1)],1)],1)],1)],1),_c('v-expand-transition',[(_setup.create_building_aplication_data.architects_array_data.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Attached Architect")])]),_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
                                            {
                                                text: 'Architect Name',
                                                value: 'customer_name',
                                                fn: (architectsData) => architectsData.first_name ? architectsData.first_name + ' ' + architectsData.last_name : architectsData.organisation_name

                                            },

                                            {
                                                text: 'Architect No',
                                                value: 'architect_number',

                                            },

                                            {
                                                text: 'Telephone No',
                                                value: 'phone_number',

                                            },

                                            {
                                                text: 'Email',
                                                value: 'email_address',

                                            },

                                            { text: 'Remove Architect', value: 'remove_architect', action: true },

                                        ],"items":_setup.create_building_aplication_data.architects_array_data,"emptyText":"No Architects Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.remove_architect",fn:function({ item: architect }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.removeClient,"text":"Remove Architect","inTable":""},on:{"click":function($event){return _setup.onRemoveArchitectFromApplication()}}})]}}],null,false,1064137923)})],1)],1)],1)],1)],1):_vm._e()],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_setup.changeStepB}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_setup.prevStepB}},[_vm._v(" Previous ")])],1)]:_vm._e(),(!(_setup.store.getters[_setup.GET_USER_DATA].profile.role_name == 'Structural Engineer'))?[_c('v-stepper-step',{attrs:{"complete":_setup.stepper_position > 3,"step":_setup.role_found ? '2' : '3'}},[_vm._v(" Add Structural Engineer(Optional) ")]),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h6 text-center font-weight-bold primary--text"},[_vm._v(" Structural Engineers ")])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c(_setup.LabelledField,{attrs:{"label":"Search"}},[_c('v-text-field',{staticClass:"background text-field",attrs:{"outlined":"","dense":"","clearable":""},model:{value:(_setup.searchModel_strucEngs),callback:function ($$v) {_setup.searchModel_strucEngs=$$v},expression:"searchModel_strucEngs"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.LoadingArea,{attrs:{"loading":_setup.structuralEngData.fetchingData}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
                                            {
                                                text: 'Structural Engineer Name',
                                                value: 'customer_name',
                                                fn: (structuralEngData) => structuralEngData.first_name ? structuralEngData.first_name + ' ' + structuralEngData.last_name : structuralEngData.organisation_name

                                            },

                                            {
                                                text: 'Engineer No',
                                                value: 'structural_engineer_number',

                                            },

                                            {
                                                text: 'Telephone No',
                                                value: 'phone_number',

                                            },

                                            {
                                                text: 'Email',
                                                value: 'email_address',

                                            },

                                            { text: 'Add Struc Eng', value: 'add_struc_eng', action: true },

                                        ],"items":_setup.structuralEngData.fetchedData,"loading":_setup.structuralEngData.fetchingData,"emptyText":"No Structural Engineers Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.add_struc_eng",fn:function({ item: structural_eng }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.addClient,"text":"Add Struc Eng","inTable":""},on:{"click":function($event){return _setup.attachStructuralEng(structural_eng)}}})]}}],null,false,932087402)})],1)],1)],1)],1)],1)],1),_c('v-expand-transition',[(_setup.create_building_aplication_data.structural_eng_array_data.length)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Attached Structural Engineer")])]),_c('v-col',{attrs:{"cols":"12"}},[_c(_setup.Table,{attrs:{"headers":[
                                            {
                                                text: 'Structural Engineer Name',
                                                value: 'customer_name',
                                                fn: (structuralEngData) => structuralEngData.first_name ? structuralEngData.first_name + ' ' + structuralEngData.last_name : structuralEngData.organisation_name

                                            },

                                            {
                                                text: 'Engineer No',
                                                value: 'structural_engineer_number',

                                            },

                                            {
                                                text: 'Telephone No',
                                                value: 'phone_number',

                                            },

                                            {
                                                text: 'Email',
                                                value: 'email_address',


                                            },

                                            { text: 'Remove Struc Eng', value: 'remove_struc_eng', action: true },

                                        ],"items":_setup.create_building_aplication_data.structural_eng_array_data,"emptyText":"No Struc Eng Found","headClasses":"primary white--text"},scopedSlots:_vm._u([{key:"item.remove_struc_eng",fn:function({ item: struc_eng }){return [_c(_setup.ActionButton,{attrs:{"action":_setup.actions.removeClient,"text":"Remove Struc Eng","inTable":""},on:{"click":function($event){return _setup.onRemoveStructuralEngFromApplication()}}})]}}],null,false,2099367677)})],1)],1)],1)],1)],1):_vm._e()],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_setup.changeStepC}},[_vm._v(" Continue ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_setup.prevStepC}},[_vm._v(" Previous ")])],1)]:_vm._e()],2),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","justify-md":"end"}},[_c('v-col',[_c(_setup.PreviousButton,{on:{"click":_setup.verifyAndGoToPreviousStep}})],1),_c('v-col',[_c(_setup.NextButton,{on:{"click":_setup.verifyAndGoToNextStep},scopedSlots:_vm._u([{key:"loadingText",fn:function(){return [_vm._v(" Please Wait ")]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }