<script setup>
import { ref, onMounted, watchEffect } from "vue";
import { my_documents, createBuildingAppData, attachedDocumentsList, snackBarInfoFunction } from "./buildings_business_logic.js";
import PreviousButton from "@/components/utils/PreviousButton.vue";
import NextButton from "@/components/utils/NextButton.vue";
import {
    fieldRequired,
    fileTypesRule,
    fileSizeRule
} from "@/utils/input_validation.js";
const { attached_documents, attached_documents_with_share_certificate, attachedDocumentsModel } = attachedDocumentsList()

const { create_building_aplication_data, building_additional_data } = createBuildingAppData()


const { snackbar_info } = snackBarInfoFunction()

const formSubmitDocuments = ref(null)

const entryLevel = ref(1)

const vertical = ref(true)
const valid = ref(true)
const altLabels = ref(false)
const editable = ref(true)
const documentErrorMessages = ref(["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""])

const emit = defineEmits(['goToNextStep', 'goToPreviousStep'])

function verifyAndGoToPreviousStep() {
    emit('goToPreviousStep')

}

function verifyAndGoToNextStep() {
    //Check if the documents required are attached
    for (let i = 0; i < attachedDocumentsModel.length; i++) {
        if (typeof attachedDocumentsModel[i].name == "undefined") {
            entryLevel.value = i + 1;

            const fileInput = document.getElementById('submitFile' + i)

            //If statement below checks whether the document is Optional, if it is, then we can skip this loop for that doc
            if (my_documents.value[i]) {
                if (my_documents.value[i].includes("Optional")) {
                    console.log("Inside continue")
                    continue
                }

            }


            //The if and else if below highlights the doc and requests the user to provide it
            if (i == 0) {
                //Ownership Document File
                documentErrorMessages.value[0] = "Please Provide the required file";
                fileInput.scrollIntoView({ block: "center", behavior: "smooth" })
                return;

            } else if (i == 1) {
                //Architectural Plan File
                documentErrorMessages.value[1] = "Please Provide the required file";
                fileInput.scrollIntoView({ block: "center", behavior: "smooth" })
                return;

            } else if (i == 2) {
                //Practicing License File
                documentErrorMessages.value[2] = "Please Provide the required file";
                fileInput.scrollIntoView({ block: "center", behavior: "smooth" })
                return;

            } else if (i == 3) {
                //Practicing License File
                documentErrorMessages.value[3] = "Please Provide the required file";
                return;

            } else if (i == 4) {
                //Structural Plan File
                documentErrorMessages.value[4] = "Please Provide the required file";
                fileInput.scrollIntoView({ block: "center", behavior: "smooth" });
                return;

            } else if (i == 5) {
                documentErrorMessages.value[5] = "Please Provide the required file";
                fileInput.scrollIntoView({ block: "center", behavior: "smooth" });
                return;

            } else if (i == 6) {
                documentErrorMessages.value[6] = "Please Provide the required file";
                fileInput.scrollIntoView({ block: "center", behavior: "smooth" });
                return;

            } else if (i == 7) {
                documentErrorMessages.value[7] = "Please Provide the required file";
                fileInput.scrollIntoView({ block: "center", behavior: "smooth" });
                return;

            }

        }


    }


    //Check if the file provided is outside what is expected
    for (let i = 0; i < attachedDocumentsModel.length; i++) {
        //Check if document is a File before checking what type it is
        if (typeof attachedDocumentsModel[i].name != "undefined") {
            if (attachedDocumentsModel[i].type != "application/pdf" && attachedDocumentsModel[i].type != "image/png" && attachedDocumentsModel[i].type != "image/jpeg" && attachedDocumentsModel[i].type != "image/jpg") {
                entryLevel.value = i + 1;
                documentErrorMessages.value[i] = "Expected file types are 'application/pdf', 'image/png', 'image/jpeg', 'image/jpg'";
                return

            }

        }

    }

    //Check if the file size is above what is expected
    for (let i = 0; i < attachedDocumentsModel.length; i++) {
        if (attachedDocumentsModel[i].size > 20000000) {
            entryLevel.value = i + 1;
            documentErrorMessages.value[i] = "File is too Large. Max of 20Mb allowed.";
            return

        }
    }



    //Finally validates the form
    if (!formSubmitDocuments.value.validate()) {
        snackbar_info.snackbar_message = "Errors Found, please check on the document attached section"
        snackbar_info.snackbar = true
        snackbar_info.snackbar_timeout = 3000

        return

    }

    emit('goToNextStep')

}

function returnMyAsterix(parameter) {
    if (parameter.includes("Optional")) {
        return "";
    } else {
        return "*";
    }

}

function returnMyIcon(index) {

    if (attachedDocumentsModel[index] != null) {
        if (
            attachedDocumentsModel[index].name == "" ||
            attachedDocumentsModel[index].name == null
        ) {
            return "";

        } else {
            return "mdi-check-circle";

        }
    }

}

function returnDocumentRules(parameter) {
    if (parameter.includes("Optional")) {
        return [];
    } else {
        return [fieldRequired, fileSizeRule, fileTypesRule];
    }

}

function nextStep(n) {
    if (n === this.steps) {
        this.entryLevel = 1;
    } else {
        this.entryLevel = n + 1;
    }

}

watchEffect(() =>{
    if(building_additional_data.property_ownership == 'Share Certificate'){
        my_documents.value = attached_documents_with_share_certificate

    }
    else{
        my_documents.value = attached_documents

    }

})

</script>

<template>
    <v-container fluid class="pa-0">

        <v-snackbar v-model="snackbar_info.snackbar" :timeout="snackbar_info.snackbar_timeout" top
            :color="snackbar_info.color">
            <span>{{ snackbar_info.snackbar_message }}</span>
        </v-snackbar>

        <v-stepper v-model="entryLevel" :vertical="vertical" :alt-labels="altLabels" class="elevation-0">
            <template v-if="vertical">
                <v-form ref="formSubmitDocuments" v-model="valid" lazy-validation>
                    <v-container class="bg-surface-variant">
                        <v-row no-gutters>
                            <v-col v-for="(n, index) in my_documents.length" :key="index" cols="6">
                                <template>
                                    <v-stepper-step :key="`${n}-step`" :complete="entryLevel > n" :step="n"
                                        :editable="editable">
                                        Step {{ n }} : {{ my_documents[index] }}
                                        <span class="error--text">{{
                                            returnMyAsterix(my_documents[index])
                                            }}</span>
                                        <v-icon right> {{ returnMyIcon(index) }}</v-icon>
                                    </v-stepper-step>

                                    <v-stepper-content :key="`${n}-content`" :step="n">
                                        <v-file-input show-size counter chips
                                            accept="application/pdf ,image/png ,image/jpeg ,image/jpg" :rules="returnDocumentRules(my_documents[index])
                                                " :error-messages="documentErrorMessages[index]"
                                            v-model="attachedDocumentsModel[index]" :id="'submitFile' + index"
                                            label="Attach Document." validate-on-blur></v-file-input>

                                        <v-btn color="primary" @click="nextStep(n)">
                                            Continue
                                        </v-btn>

                                        <v-btn text>Cancel</v-btn>
                                    </v-stepper-content>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </template>

        </v-stepper>

        <v-row class="mt-2" justify="center" justify-md="end">
            <v-col>
                <PreviousButton @click="verifyAndGoToPreviousStep" />

            </v-col>

            <v-col>
                <NextButton @click="verifyAndGoToNextStep">
                    <template #loadingText> Please Wait </template>
                </NextButton>

            </v-col>
        </v-row>

    </v-container>

</template>