import { passwordSpecialCharacters } from "./constants";
import { convertStringToJsDate } from "./functions";

export const fieldRequired = (v) => {
  return (
    (v !== null && v !== "" && v !== undefined) || "This field is required"
  );
};

export const fileTypesRule = (value) => {
  return (
    (!value || value.type == "application/pdf" || value.type == "image/png" || value.type == "image/jpeg" || value.type == "image/jpg" || "Expected File Types are: Pdf files, Png, Jpeg and Jpg Images!")
  )

};

export const fileSizeRule = (value) => {
  return(
    (!value || !value.length || value[0].size < 2000000 || "Avatar size should be less than 2 MB!")
  )

};

export function minLength(minFieldLength) {
  return (v) =>
    (v !== null && v !== undefined && v.length >= minFieldLength) ||
    `This field requires a minimum of ${minFieldLength} characters`;
}

export function validateIfNotEmpty(rules) {
  return (v) => {
    if (v === null || v === undefined || v.length === 0) {
      return true;
    }

    for (let rule of rules) {
      const result = rule(v);
      if (result !== true) {
        return result;
      }
    }
    return true;
  };
}

export const emailValid = (v) =>
  !v ||
  /^[a-zA-Z0-9\.!#$%&'*+/=?^_~-]+@([a-zA-Z0-9]+\.)+[a-zA-Z]{2,3}$/.test(v) ||
  "E-mail must be valid";

export const passwordRules = [
  (v) => (v && v.length >= 8) || "Minimum 8 characters",
  (v) => /(?=.*[A-Z])/.test(v) || "Must have at least one uppercase character",
  (v) => /(?=.*\d)/.test(v) || "Must have at least one number",
  (v) =>
    /([!@#$%.])/.test(v) ||
    `Must have at least one special character (${passwordSpecialCharacters})`,
];

export function passwordConfirmation({ passwordGetter }) {
  return (v) => {
    const currentPasswordValue = passwordGetter();
    const passwordConfMatchesPassword = v === currentPasswordValue;
    return passwordConfMatchesPassword || "Must match password";
  };
}

export const isNumber = (v) => !isNaN(v) || "Please enter a valid number";

export const floatOnly = (v) => {
  const pattern = /^[+-]?\d+(\.\d+)?$/;
  return pattern.test(v) || "Please Include Numbers Only.";
};

export function doesNotMatch(invalidValues) {
  return (v) => {
    const valueIsInvalid = invalidValues.includes(v);
    if (valueIsInvalid) {
      return "Invalid value";
    }
    return true;
  };
}

export function minValue(minValue, errorMsg = null) {
  return (v) => {
    const numericValue = Number(v);
    const valueValid = numericValue >= minValue;
    if (valueValid) {
      return true;
    }
    return errorMsg?? `Value must be at least ${minValue}`;
  };
}

export function compareValue({
  getOtherValueFn,
  operator,
  errorMsg = "Field not valid",
}) {
  return (val) => {
    const isNumber = !isNaN(val) && !!val;
    if (isNumber) {
      const otherValue = getOtherValueFn();
      const comparisonPasses = eval(`${val} ${operator} ${otherValue}`);
      if (comparisonPasses) {
        return true;
      }
    }
    return errorMsg;
  };
}

export function exactCharacterLength(length) {
  return (v) => {
    return (!!v && v.length === length) || `Must be ${length} characters long`;
  };
}

export function minDate(minDate) {
  return (v) => {
    return (
      Date.now(v) >= Date.now(minDate) || `Date must be greater than ${minDate}`
    );
  };
}

export function compareDates({
  otherDateRef,
  operator,
  errorMsg = "Field Invalid",
}) {
  return (val) => {
    const ms = convertStringToJsDate(val).getTime();
    const otherDateMs = Date.parse(otherDateRef.value);
    const comparisonPasses = eval(`${ms} ${operator} ${otherDateMs}`);
    if (comparisonPasses) {
      return true;
    }
    return errorMsg;
  };
}

export function isWholeNumber(val) {
  const hasPeriod = val?.match?.(/\./);
  const value = Number(val);
  return (
    (!hasPeriod && Math.floor(value) === value) ||
    "Value must not have decimal places"
  );
}
