<script setup>
import ViewPropertyDetails from "./ViewPropertyDetails.vue";
import ViewPropertyBills from "./ViewPropertyBills.vue";
import ViewPropertyRates from "./ViewPropertyRates.vue";
import GenerateRatesBillDialog from "./GenerateRatesBillDialog.vue";
import ViewPdf from "@/components/utils/ViewPdf.vue";
import ViewBill from "../../billing/view_bills/ViewBill.vue";
import Dialog from "@/components/utils/Dialog.vue";
import { useDownloadFile, useRouter } from "@/utils/vue_helpers";
import { routes } from "@/router/routes";
import { computed, ref, onMounted } from "vue";
import { propertyRatesPayable } from "@/utils/functions";

const props = defineProps({
  property: {
    type: Object,
    default: null,
  },
  showGoBack: {
    type: Boolean,
    default: false,
  },
  moduleInUse: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(["reloadProperties"]);

const pages = {
  VIEW_PROPERTY: 0,
  VIEW_BILL: 1,
};
const currentPage = ref(pages.VIEW_PROPERTY);

const billToView = ref(null);
const billToViewNotEmpty = computed(() => {
  return !!billToView.value;
});

const goTo = {
  viewProperty() {
    currentPage.value = pages.VIEW_PROPERTY;
  },
  viewBill(bill) {
    billToView.value = bill;
    currentPage.value = pages.VIEW_BILL;
  },
};

const propertyForBills = ref({ ...props.property });

const lastPropertyLog = ref(null);
const lastPropertyLogNotEmpty = computed(() => {
  return !!lastPropertyLog.value;
});

const showGenerateRatesBillDialog = ref(false);

const showPdfOpen = ref(false);
const billToViewId = ref(null);

const { downloadFile } = useDownloadFile();

function onPrintBillClicked({ billId, billNumber }) {
  downloadFile({ billId: billId, billNumber: billNumber });
  // billToViewId.value = billId
  // showPdfOpen.value = true
}

const ratesNotPayableDialogOpen = ref(false);

const ratesNotPayableErrorMsg = ref(null);

async function onGenerateRatesBillClicked() {
  try {
    // lastPropertyLog.value = await getLastPropertyLog(props.property);
    // if (lastPropertyLogNotEmpty.value) {
    //   showGenerateRatesBillDialog.value = true;
    // }
    const ratesPayable = propertyRatesPayable(props.property);
    if (ratesPayable === true) {
      showGenerateRatesBillDialog.value = true;
    } else {
      ratesNotPayableErrorMsg.value = ratesPayable;
      ratesNotPayableDialogOpen.value = true;
    }
  } catch (error) {
    console.log(error);
  }
}

function onRatesBillGenerated(bill) {
  showGenerateRatesBillDialog.value = false;
  addNewRatesBillToProperty(bill);
}

function addNewRatesBillToProperty(bill) {
  const invoices = [bill.bills_invoices[0].invoice];
  bill.invoices = invoices;
  const propertyWithNewRatesBill = { ...propertyForBills.value };
  propertyWithNewRatesBill.rates?.bills?.unshift(bill);
  propertyForBills.value = propertyWithNewRatesBill;
}

const { router } = useRouter();
function onPayBillClicked(bill) {
  const paymentRoute = routes.payment.withQuery({
    billNumbersToPayAsString: bill.bill_number,
    directPayment: true,
    billsToPay: [bill],
  });
  router.value.push(paymentRoute);
}

onMounted(() => {
  console.log("props.moduleInUse A")
  console.log(props.moduleInUse)

  
})
</script>

<template>
  <v-container fluid class="pa-0">
    <v-tabs-items v-model="currentPage">
      <v-tab-item :value="pages.VIEW_PROPERTY">
        <v-row>
          <v-col cols="1">
            <v-btn plain @click="$emit('goBackClicked')">
              <v-icon left class="">mdi-arrow-left</v-icon>
              <span class="text-caption text-none">Back To My Plots</span>
            </v-btn>
          </v-col>

          <v-col cols="10" class="text-h6 primary--text">
            <div class="text-center">View Property</div>
          </v-col>

          <v-spacer />
        </v-row>

        <!-- <v-row justify="end">
                    <v-col cols="auto">
                        <v-btn depressed class="blue text-none white--text" @click="onGeneratePropertySearchClicked"
                            :loading="generatePropertySearch.isLoading.value">
                            Generate Property Search
                            <v-icon right>mdi-home-search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row> -->

        <v-row>
          <v-col cols="12">
            <ViewPropertyDetails :property="property" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <ViewPropertyBills
              :property="propertyForBills"
              @printBillClicked="onPrintBillClicked"
              @payBillClicked="onPayBillClicked"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <ViewPropertyRates
              :property="property"
              :moduleInUse="moduleInUse"
              :ratesBillSectionLoading="fetchingLastPropertyLog"
              @generateRatesBillClicked="onGenerateRatesBillClicked"
            />
          </v-col>
        </v-row>

        <GenerateRatesBillDialog
          :rates="property.rates"
          :property="property"
          :dialogOpen.sync="showGenerateRatesBillDialog"
          @ratesBillGenerated="onRatesBillGenerated"
        />

        <Dialog :dialogOpen.sync="ratesNotPayableDialogOpen" max-width="600">
          <template #title>
            <span class="error--text">Cannot Pay Property Rates</span>
          </template>
          <template #text
            ><span v-html="ratesNotPayableErrorMsg"></span
          ></template>
        </Dialog>

        <ViewPdf :billId="billToViewId" :open.sync="showPdfOpen" />
      </v-tab-item>

      <v-tab-item :value="pages.VIEW_BILL">
        <v-row>
          <v-col cols="12">
            <ViewBill
              v-if="billToViewNotEmpty"
              :bill="billToView"
              showGoBack
              @goBackClicked="goTo.viewProperty"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
